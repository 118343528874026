import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBaGcY6lRTNKQ6QwVz7mYUEB-CK1g_Fb1M',
  authDomain: 'codex-alamor.firebaseapp.com',
  projectId: 'codex-alamor',
  storageBucket: 'codex-alamor.appspot.com',
  messagingSenderId: '664015057340',
  appId: '1:664015057340:web:b410d8e2f3186bb472bbb9',
  measurementId: 'G-9R822FQDPY',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
